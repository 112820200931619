import React from "react";
import User from "../Component/User/User";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import { GetToken } from "../Component/utils";
import Contact from "../Component/User/Contact";
import Category from "../Component/Category/Category";
import Question from "../Component/Question/Question";
import Bettel from "../Component/Bettel/Bettel";
import BettelReq from "../Component/Bettel/BettelReq";
import WithdrawalRequest from "../Component/WithdrawalRequest/WithdrawalRequest";
import Kyc from "../Component/Kyc/Kyc";
import Transaction from "../Component/Transaction/Transaction";
import UserDetails from "../Component/User/UserDetails";
import Bettalview from "../Component/Bettel/Bettalview";
import Setting from "../Component/Setting/Setting";
import SubCategory from "../Component/Category/SubCategory";
import SubSubCategory from "../Component/Category/SubSubCategory";
import Product from "../Component/Product/Product";
import Sale from "../Component/Sale/Sale";
import SaleReturn from "../Component/Sale/SaleReturn";
import MqChalenge from "../Component/Chalenge/MqChalenge";
import DuqeChalenge from "../Component/Chalenge/DuqeChalenge";
import Ticket from "../Component/Ticket/Ticket";
import AddProduct from "../Component/Product/AddProduct";
import UpdateProduct from "../Component/Product/UpdateProduct";
import MonthReports2 from "../Component/MonthReports/MonthReports";
import GpgCt from "../Component/Sale/GpgCt";
import GpgDuqe from "../Component/Sale/GpgDuqe";
import GpgMq from "../Component/Sale/GpgMq";
import Slider from "../Component/Slider/Slider";
import Dynemicbtn from "../Component/Slider/Dynemicbtn";
import LostFound from "../Component/LostFound/LostFound";
import Package from "../Component/Package/Package";
import Video from "../Component/Slider/Video";
import SellerPay from "../Component/User/SellerPay";
import EducationCate from "../Component/Education/EducationCate";
import EducationAll from "../Component/Education/EducationAll";
import EducationAdd from "../Component/Education/EducationAdd";
import Exam from "../Component/Slider/Exam";
import Marques from "../Component/Slider/Marquees";
import Certificate from "../Component/LostFound/Certificate";
import Listing from "../Component/Listing/Listing";
import EducationEdit from "../Component/Education/EducationEdit";
import Blog from "../Component/Category/Blog";
import Pages from "../Component/Ticket/Pages";
import Vendor from "../Component/User/Vendor";
import SetLocation from "../Component/set-location/SetLocation";
import JobCategory from "../Component/Job/JobCategory";
import Brand from "../Component/Category/Brand";
import ReffralProgram from "../Component/User/ReffralProgram";
import LavelIncome from "../Component/Income/LavelIncome";
import DireactIncome from "../Component/Income/DireactIncome";
import BonusIncome from "../Component/Income/BonusIncome";
import MatchingIncome from "../Component/Income/MatchingIncome";
import ReMAtchingIncome from "../Component/Income/ReMAtchingIncome";
import RedirectIncome from "../Component/Income/RedirectIncome";
import RelevelIncome from "../Component/Income/RelevelIncome";
import Branch from "../Component/Category/Branch";
import LevelDynemic from "../Component/Slider/LevelDynemic";
import Francise from "../Component/User/Francise";
import FrSale from "../Component/Sale/FrSale";

function PageRouter() {
  function RequireAuth({ children }) {
    const token = GetToken();
    return token ? children : <Navigate to="/login" replace />;
  }
  function AlreadyLogin({ children }) {
    const token = GetToken();
    return token ? <Navigate to="/" replace /> : children;
  }
  return (
    <div>
      <Routes>
        {/* <Route path="/forgot-password" element={<AlreadyLogin> <ForgotPassword /></AlreadyLogin>} /> */}
        <Route
          path="/login"
          element={
            <AlreadyLogin>
              <Login />
            </AlreadyLogin>
          }
        />
        <Route
          element={
            <RequireAuth>
              {" "}
              <LayoutMain />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/franchise" element={<Francise />} />
          <Route path="/level-income" element={<LavelIncome />} />
          <Route path="/direct-income" element={<DireactIncome />} />
          <Route path="/bonus-income" element={<BonusIncome />} />
          <Route path="/matching-income" element={<MatchingIncome />} />
          <Route path="/rematching-income" element={<ReMAtchingIncome />} />
          <Route path="/redirect-income" element={<RedirectIncome />} />
          <Route path="/relevel-income" element={<RelevelIncome />} />
          <Route path="/user-details/:id" element={<UserDetails />} />
          <Route path="/battel-view/:id" element={<Bettalview />} />
          <Route path="/users/contact/:id" element={<Contact />} />
          <Route path="/update-product/:id" element={<UpdateProduct />} />
          <Route path="/education-update/:id" element={<EducationEdit />} />
          <Route path="/bettel" element={<Bettel />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/kyc-pending" element={<Kyc />} />
          <Route path="/winner-request" element={<BettelReq />} />
          <Route path="/withdrawal-request" element={<WithdrawalRequest />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/category" element={<Category />} />
          <Route path="/job-category" element={<JobCategory />} />
          <Route path="/product" element={<Product />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/sale" element={<Sale />} />
          <Route path="/fr-sale" element={<FrSale />} />
          <Route path="/gpg-ct" element={<GpgCt />} />
          <Route path="/gpg-mq" element={<GpgMq />} />
          <Route path="/gpg-duqe" element={<GpgDuqe />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/slider" element={<Slider />} />
          <Route path="/marques" element={<Marques />} />
          <Route path="/level-dynemic" element={<LevelDynemic />} />
          <Route path="/exam" element={<Exam />} />
          <Route path="/dynemicbtn" element={<Dynemicbtn />} />
          <Route path="/question" element={<Question />} />
          <Route path="/package" element={<Package />} />
          <Route path="/video" element={<Video />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/branch" element={<Branch />} />
          <Route path="/pages" element={<Pages />} />
          <Route path="/education-category" element={<EducationCate />} />
          <Route path="/education-all" element={<EducationAll />} />
          <Route path="/add-education" element={<EducationAdd />} />
          <Route path="/vendor-payment" element={<SellerPay />} />
          <Route path="/referral-program" element={<ReffralProgram />} />
          <Route path="/lost-found" element={<LostFound />} />
          <Route path="/certificate" element={<Certificate />} />
          {/* <Route path="/month-reports" element={<MonthReports />} /> */}
          <Route path="/mq-chalenge" element={<MqChalenge />} />
          <Route path="/duqe-chalenge" element={<DuqeChalenge />} />
          <Route path="/month-reports" element={<MonthReports2 />} />
          <Route path="/sale-return" element={<SaleReturn />} />
          <Route path="/sub-category" element={<SubCategory />} />
          <Route path="/sub-sub-category" element={<SubSubCategory />} />
          <Route path="/set-location" element={<SetLocation />} />
        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import { Col } from "antd";
import HeadingBack from "../component/HeadingBack";
import { URL } from "../../Redux/common/url";
import ImageUploading from "react-images-uploading";
import FooterPageType from "../Ticket/FooterPageType";
import Pageconect from "../Ticket/Pageconect";

function Setting() {
  const [show, setShow] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showOtpBoxsata, setShowOtpBoxdata] = useState("");
  const dispatch = useDispatch();
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );
  const get_invoice_details = useSelector((state) =>
    state.allapi.get_invoice_details ? state.allapi.get_invoice_details : {}
  );
  const get_admin_profile = useSelector((state) =>
    state.allapi.get_invoice_details ? state.allapi.get_invoice_details : {}
  );

  console.log(get_invoice_details);

  const [userBuss, setuserBuss] = useState({});

  console.log(userBuss);
  var size = Object.keys(get_invoice_details).length;
  useEffect(() => {
    setStartDate(new Date(get_setting?.withdrawalstarttime));
    setStartDate2(new Date(get_setting?.withdrawalendtime));
    setuserBuss(get_setting);
  }, [get_setting]);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  useEffect(() => {
    dispatch(allapiAction.getInvoceDetails({}));
    // dispatch(allapiAction.getsetting({}));

    return () => {};
  }, []);

  const submitProduct = () => {
    try {
      const data = new FormData();

      if (!userBuss?.referAmount) {
        toast.error("please enter referAmount");
      } else if (!userBuss?.gamecommision) {
        toast.error("please enter game commision");
      } else if (!userBuss?.withdrwalcommission) {
        toast.error("please enter withdrwal commission");
      } else if (!userBuss?.maxmumwithdrwal) {
        toast.error("please enter maxmum withdrwal");
      } else if (!userBuss?.minimumwithdrwal) {
        toast.error("please enter minimum withdrwal");
      } else if (!userBuss?.minimumrecharge) {
        toast.error("please enter minimum recharge");
      } else if (!userBuss?.maxmumrecharge) {
        toast.error("please enter maxmum recharge");
      } else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        // data.append('gstNo', userBuss?.gstNo)
        // data.append('companyName', userBuss?.companyName)
        // data.append('email', userBuss?.email)
        // data.append('address', userBuss?.address)
        // data.append('fb', userBuss?.fb)
        // data.append('insta', userBuss?.insta)
        // data.append('twitter', userBuss?.twitter)
        // data.append('youtube', userBuss?.youtube)
        // data.append('number', userBuss?.number)
        // data.append('pinterst', userBuss?.pinterst)

        // data.append('invoiceLogo', images[0]?.file)

        userBuss.withdrawalstarttime = startDate;
        userBuss.withdrawalendtime = startDate2;

        // console.log(startDate);
        // console.log(startDate2);

        dispatch(allapiAction.createSetting(userBuss));
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false);
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  //   const submitChangeVerify2 = () => {
  //     if (!showOtpBoxsata) {
  //       toast.error('please enter otp')
  //     } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
  //       toast.error('otp invalid')
  //     } else {
  //       dispatch(
  //         allapiAction.verifyWhatsapp({
  //           whatsappNumber: userBuss?.whatsappNumber,
  //           otp: showOtpBoxsata,
  //         })
  //       )
  //       setShowOtpBox(false)
  //       setShow(false)
  //     }
  //   }
  const [showLogo, setshowLogo] = useState(false);
  const [showLogoLogin, setshowLogoLogin] = useState(false);
  const [showLogoRegister, setshowLogoRegister] = useState(false);
  const [showLogoHome, setshowLogoHome] = useState(false);
  const [showLogoHome1, setshowLogoHome1] = useState(false);
  const handleCloseshowLogo = () => setshowLogo(false);
  const handleCloseshowLogoHome = () => setshowLogoHome(false);
  const handleCloseshowLogoHome1 = () => setshowLogoHome1(false);
  const handleShowshowLogoHome = () => setshowLogoHome(true);

  const [imagesss, setImagesss] = React.useState([]);
  const [imagesssLogIn, setImagesssLogIn] = React.useState([]);
  const [imagesssRegister, setImagesssRegister] = React.useState([]);
  const [imagesss3, setImagesss3] = React.useState([]);
  const [imagesss4, setImagesss4] = React.useState([]);
  const [showeditBox, setshoweditBox] = useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const handleCloseeditBox = () => setshoweditBox(false);
  const handleShoweditBox = () => setshoweditBox(true);
  const handleCloseshowLogoLogin = () => setshowLogoLogin(false);
  const handleCloseshowLogoRegister = () => setshowLogoRegister(false);
  const maxNumberss = 1;
  const handleShowshowLogo = () => setshowLogo(true);
  const handleShowshowLogoLogin = () => setshowLogoLogin(true);
  const handleShowshowLogoRegister = () => setshowLogoRegister(true);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  const onChangess = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesss(imageList);
  };

  const onChangessLogIn = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesssLogIn(imageList);
  };

  const onChangessRegister = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesssRegister(imageList);
  };
  const onChangess3 = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesss3(imageList);
  };
  const onChangess4 = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesss4(imageList);
  };
  // const handleShowshowLogoHome1 = () => {
  //   setHomePop(home_pop)

  //   const setImg = home_pop?.image ? [{data_url: URL.API_BASE_URL + home_pop?.image}] : []

  //   setImagesss4(setImg)

  //   setshowLogoHome1(true)
  // }

  const handleShowshowLogoHome3 = (e) => {
    setImagesss3([{ data_url: URL.API_BASE_URL + e }]);
    setshowLogoHome(true);
  };
  const hendlehomeLogoUpdate = () => {
    try {
      const data = new FormData();

      if (imagesss3?.length == 0) {
        toast.error("please select logo Image");
      } else {
        // setLoading(true);

        if (imagesss3[0]?.file) {
          // roledata?.role_name
          data.append("homeLogo", imagesss3[0]?.file);
          dispatch(allapiAction.updateHomeLogo(data));
        }

        setshowLogoHome(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };
  const hendleLogoUpdate = () => {
    try {
      const data = new FormData();

      if (imagesss?.length == 0) {
        toast.error("please select logo Image");
      } else {
        // setLoading(true);

        data.append("invoiceLogo", imagesss[0]?.file);

        dispatch(allapiAction.updateInvoiceLogo(data));
        setshowLogo(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  const hendleLogoUpdate1 = () => {
    try {
      const data = new FormData();

      if (imagesssLogIn?.length == 0) {
        toast.error("please select logo Image");
      } else {
        // setLoading(true);

        data.append("loginLogo", imagesssLogIn[0]?.file);

        dispatch(allapiAction.updateLoginLogo(data));
        // setshowLogo(false)

        setshowLogoLogin(false);

        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  const hendleLogoUpdate2 = () => {
    try {
      const data = new FormData();

      if (imagesssRegister?.length == 0) {
        toast.error("please select logo Image");
      } else {
        // setLoading(true);

        data.append("registerLogo", imagesssRegister[0]?.file);

        dispatch(allapiAction.updateRegisterLogo(data));
        // setshowLogo(false)
        setshowLogoRegister(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  const [bussEdit, setbussEdit] = useState({
    gstNo: "",
    companyName: "",
    email: "",
    address: "",
    fb: "",
    insta: "",
    twitter: "",
    youtube: "",
    number: "",
    pinterst: "",
    copyRight: "",
  });

  useEffect(() => {
    setbussEdit({
      gstNo: get_admin_profile?.gstNo,
      companyName: get_admin_profile?.companyName,
      email: get_admin_profile?.email,
      address: get_admin_profile?.address,
      fb: get_admin_profile?.fb,
      insta: get_admin_profile?.insta,
      twitter: get_admin_profile?.twitter,
      youtube: get_admin_profile?.youtube,
      number: get_admin_profile?.number,
      pinterst: get_admin_profile?.pinterst,
      copyRight: get_admin_profile?.copyRight,
      gst: get_admin_profile?.gst,
      shipping: get_admin_profile?.shipping,
      shipping2: get_admin_profile?.shipping2,
      achname: get_admin_profile?.achname,
      ifsc: get_admin_profile?.ifsc,
      ac: get_admin_profile?.ac,
      upi: get_admin_profile?.upi,

      affilateincome: get_admin_profile?.affilateincome,
      advisorBounse: get_admin_profile?.advisorBounse,
      referBonse: get_admin_profile?.referBonse,
      araedistributors: get_admin_profile?.araedistributors,
      araedistributorsdireact: get_admin_profile?.araedistributorsdireact,
      districtdistributors: get_admin_profile?.districtdistributors,
      districtdistributorsdireact:
        get_admin_profile?.districtdistributorsdireact,
      statedistributors: get_admin_profile?.statedistributors,
      statedistributorsdireact: get_admin_profile?.statedistributorsdireact,
    });

    // setwhatsappNum(get_admin_profile?.user_id?.whatsappNumber)
    // setcountryCode(get_admin_profile?.user_id?.countryCode)
  }, [get_admin_profile]);

  const hendleChangeupdate = (e) => {
    const { name, value } = e.target;
    setbussEdit({ ...bussEdit, [name]: value });
  };

  const submitupdateBussProfile = () => {
    try {
      const data = new FormData();

      if (!bussEdit?.gstNo) {
        toast.error("please enter GST No.");
      } else if (!bussEdit?.companyName) {
        toast.error("please enter company name");
      } else if (!bussEdit?.email) {
        toast.error("please enter email");
      } else if (!bussEdit?.address) {
        toast.error("please enter address");
      } else {
        console.log(bussEdit?.pinterst);

        dispatch(
          allapiAction.updateInvoiceProfile({
            gstNo: bussEdit?.gstNo,
            companyName: bussEdit?.companyName,
            email: bussEdit?.email,
            address: bussEdit?.address,
            fb: bussEdit?.fb,
            insta: bussEdit?.insta,
            twitter: bussEdit?.twitter,
            youtube: bussEdit?.youtube,
            number: bussEdit?.number,
            pinterst: bussEdit?.pinterst,
            copyRight: bussEdit?.copyRight,
            gst: bussEdit?.gst,
            shipping: bussEdit?.shipping,
            shipping2: bussEdit?.shipping2,
            achname: bussEdit?.achname,
            ifsc: bussEdit?.ifsc,
            ac: bussEdit?.ac,
            upi: bussEdit?.upi,
            //
            araedistributors: bussEdit?.araedistributors,
            araedistributorsdireact: bussEdit?.araedistributorsdireact,
            districtdistributors: bussEdit?.districtdistributors,
            districtdistributorsdireact: bussEdit?.districtdistributorsdireact,
            statedistributors: bussEdit?.statedistributors,
            statedistributorsdireact: bussEdit?.statedistributorsdireact,
            referBonse: bussEdit?.referBonse,
            advisorBounse: bussEdit?.advisorBounse,
            affilateincome: bussEdit?.affilateincome,

            // country: selectedOptionEdit?.name,
            // city: selectedOptionCityEdit?.name,
          })
        );
        setshoweditBox(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Setting"}
            // subHeading={`Total ${get_transaction_list?.totalDocs || 0} Transaction`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          {/* <Tooltip title="Update">
            <span className="jop_btn" onClick={handleShow}>
              Update
             
            </span>
          </Tooltip> */}
        </div>
      </div>

      <Modal show={showeditBox} onHide={handleCloseeditBox}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className="mb-3">
                <label className="form-label">Gst No</label>
                <input
                  name="gstNo"
                  className="form-control"
                  placeholder="Enter gstNo"
                  type="text"
                  value={bussEdit?.gstNo}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Company Name</label>
                <input
                  name="companyName"
                  className="form-control"
                  placeholder="Enter company name"
                  type="text"
                  value={bussEdit?.companyName}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="text"
                  value={bussEdit?.email}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>

              {/* <div className='mb-3'>
                <label className='form-label'>Select Country</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionEdit}
                  onChange={setSelectedOptionEdit}
                  options={subListedit}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Select City</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionCityEdit}
                  onChange={setSelectedOptionCityEdit}
                  options={subListCityedit}
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label">Address</label>
                <input
                  name="address"
                  className="form-control"
                  placeholder="Enter address"
                  type="text"
                  value={bussEdit?.address}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Ac holder name</label>
                <input
                  name="achname"
                  className="form-control"
                  placeholder="Enter achname"
                  type="text"
                  value={bussEdit?.achname}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">ac</label>
                <input
                  name="ac"
                  className="form-control"
                  placeholder="Enter ac"
                  type="text"
                  value={bussEdit?.ac}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">ifsc</label>
                <input
                  name="ifsc"
                  className="form-control"
                  placeholder="Enter ifsc"
                  type="text"
                  value={bussEdit?.ifsc}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">upi</label>
                <input
                  name="upi"
                  className="form-control"
                  placeholder="Enter upi"
                  type="text"
                  value={bussEdit?.upi}
                  onChange={(e) => {
                    hendleChangeupdate(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseeditBox}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitupdateBussProfile();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLogoLogin} onHide={handleCloseshowLogoLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Change login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className="mb-3">
                <label className="form-label">login banner</label>
                <ImageUploading
                  // multiple

                  value={imagesssLogIn}
                  onChange={onChangessLogIn}
                  maxNumber={maxNumberss}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button className='btn btn-primary btn-sm rounded' onClick={onImageRemoveAll}>
                        Remove all images
                      </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded mt-2"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseshowLogoLogin}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleLogoUpdate1();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLogoRegister} onHide={handleCloseshowLogoRegister}>
        <Modal.Header closeButton>
          <Modal.Title>Change Register Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className="mb-3">
                <label className="form-label">Register Banner</label>
                <ImageUploading
                  // multiple

                  value={imagesssRegister}
                  onChange={onChangessRegister}
                  maxNumber={maxNumberss}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button className='btn btn-primary btn-sm rounded' onClick={onImageRemoveAll}>
                        Remove all images
                      </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded mt-2"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseshowLogoRegister}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleLogoUpdate2();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* invoice */}
      <Modal show={showLogo} onHide={handleCloseshowLogo}>
        <Modal.Header closeButton>
          <Modal.Title>Change Invoice logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className="mb-3">
                <label className="form-label">Invoice Logo</label>
                <ImageUploading
                  // multiple
                  value={imagesss}
                  onChange={onChangess}
                  maxNumber={maxNumberss}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button className='btn btn-primary btn-sm rounded' onClick={onImageRemoveAll}>
                        Remove all images
                      </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded mt-2"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseshowLogo}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleLogoUpdate();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* home logo */}
      <Modal show={showLogoHome} onHide={handleCloseshowLogoHome}>
        <Modal.Header closeButton>
          <Modal.Title>Change Qr</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className="mb-3">
                <label className="form-label"> Logo</label>
                <ImageUploading
                  // multiple
                  value={imagesss3}
                  onChange={onChangess3}
                  maxNumber={maxNumberss}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded mt-2"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseshowLogoHome}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendlehomeLogoUpdate();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">refer amount</label>
                <input
                  name="referAmount"
                  className="form-control"
                  placeholder="Enter refer Amount"
                  type="number"
                  value={userBuss?.referAmount}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">game commision</label>
                <input
                  name="gamecommision"
                  className="form-control"
                  placeholder="Enter game commision"
                  type="number"
                  value={userBuss?.gamecommision}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrwal commission</label>
                <input
                  name="withdrwalcommission"
                  className="form-control"
                  placeholder="Enter withdrwal commission"
                  type="number"
                  value={userBuss?.withdrwalcommission}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">minimum withdrawal</label>
                <input
                  name="minimumwithdrwal"
                  className="form-control"
                  placeholder="Enter  minimum withdrawal"
                  type="number"
                  value={userBuss?.minimumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum withdrawal</label>
                <input
                  name="maxmumwithdrwal"
                  className="form-control"
                  placeholder="Enter  maxmum withdrawal"
                  type="number"
                  value={userBuss?.maxmumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Minimum recharge</label>
                <input
                  name="minimumrecharge"
                  className="form-control"
                  placeholder="Enter minimum recharge"
                  type="number"
                  value={userBuss?.minimumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum recharge</label>
                <input
                  name="maxmumrecharge"
                  className="form-control"
                  placeholder="Enter maxmum recharge"
                  type="number"
                  value={userBuss?.maxmumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vpa</label>
                <input
                  name="vpa"
                  className="form-control"
                  placeholder="Enter vpa"
                  type="text"
                  value={userBuss?.vpa}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">name</label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={userBuss?.name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">merchant Code</label>
                <input
                  name="merchantCode"
                  className="form-control"
                  placeholder="Enter  merchant Code"
                  type="text"
                  value={userBuss?.merchantCode}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrawal start time</label>
                {/* <input
                  name='withdrawalstarttime'
                  className='form-control'
                  placeholder='Enter withdrawalstarttime'
                  type='text'
                  onChange={(e) => {
                    hendleChangerole(e)
                  }}
                /> */}
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrawal end time</label>
                {/* <input
                  name='withdrawalendtime'
                  className='form-control'
                  placeholder='Enter withdrawalendtime'
                  type='text'
                  onChange={(e) => {
                    hendleChangerole(e)
                  }}
                /> */}
                <DatePicker
                  className="form-control"
                  selected={startDate2}
                  onChange={(date) => setStartDate2(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
              {/* <div className='mb-3'>
                  <label className='form-label'>youtube</label>
                  <input
                    name='youtube'
                    className='form-control'
                    placeholder='Enter youtube'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>number</label>
                  <input
                    name='number'
                    className='form-control'
                    placeholder='Enter number'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div> */}
            </div>
            {/* ) : ( */}
            {/* <div className='card-body'>
                

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div> */}
            {/* )} */}
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>

      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <h3>Details</h3>
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={URL.API_BASE_URL + get_admin_profile?.invoiceLogo}
                  alt=""
                />
                <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                    >
                      {get_admin_profile?.companyName}
                    </a>
                    <a href="#">
                      {/* <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      /> */}
                    </a>
                  </div>

                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      {get_admin_profile?.number}
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      {get_admin_profile?.user_id?.whatsappNumber}
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      {get_admin_profile?.email}
                    </a>
                  </div>
                </div>

                <div className="d-flex my-4">
                  {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Hire Me
                  </a> */}

                  {size == 0 ? (
                    <div className="col-xl-4 text-md-end">
                      <Button variant="primary" onClick={handleShow}>
                        Create Profile
                      </Button>
                      {/* <a href="#" className="btn btn-primary">
                    {" "}
                    View live <i className="material-icons md-launch"></i>{" "}
                  </a> */}
                    </div>
                  ) : (
                    <div className=" text-md-end">
                      {/* <Button variant='primary ' onClick={handleShowwhat}>
                        Change Whatsapp No.
                      </Button> */}
                      <Button
                        variant="primary ms-2"
                        onClick={() => {
                          handleShowshowLogoHome3(get_admin_profile?.homeLogo);
                        }}
                      >
                        Change Qr
                      </Button>
                      {/* <Button
                        variant="primary ms-2"
                        onClick={handleShowshowLogo}
                      >
                        Change Invoice Logo
                      </Button>
                      <Button
                        variant="primary ms-2"
                        onClick={handleShowshowLogoLogin}
                      >
                        Change Login banner
                      </Button>
                      <Button
                        variant="primary ms-2"
                        onClick={handleShowshowLogoRegister}
                      >
                        Change Register banner
                      </Button> */}

                      <Button
                        variant="primary ms-2"
                        onClick={handleShoweditBox}
                      >
                        Edit Details
                      </Button>
                      <Button
                        variant="primary ms-2"
                        onClick={handleShoweditBox}
                      >
                        Bonse
                      </Button>
                      {/* <a href="#" className="btn btn-primary">
                 
                   View live <i className="material-icons md-launch"></i>{" "}
                 </a> */}
                    </div>
                  )}

                  <div className="me-0">
                    {/* <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button> */}
                    {/* <Dropdown1 /> */}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className="fs-2 fw-bolder">Gst No.</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {get_admin_profile?.gstNo}
                      </div>
                    </div>

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Country</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.country}</div>
                    </div> */}

                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className="fs-2 fw-bolder">Address</div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {get_admin_profile?.address}
                      </div>
                    </div>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div> */}
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <h3>Social Link</h3> */}
          <div className="me-7 mb-4">
            <h3>QR Code</h3>
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={URL.API_BASE_URL + get_admin_profile?.homeLogo}
                alt=""
              />
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div>
          {/* <div className="me-7 mb-4">
            <h3>Login banner</h3>
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={URL.API_BASE_URL + get_admin_profile?.loginLogo}
                alt=""
              />
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div>
          <div className="me-7 mb-4">
            <h3>Register Banner</h3>
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={URL.API_BASE_URL + get_admin_profile?.registerLogo}
                alt=""
              />
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div> */}

          {/* <h3>Social Link</h3>
          <div className="d-flex overflow-auto ">
            <div className="d-flex flex-wrap">
              {get_admin_profile?.fb && (
                <div className=" rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div className="d-flex align-items-center">
                    <a href={get_admin_profile?.fb} target="_blank">
                      <i className="fa-brands fa-facebook-f fs-2x"></i>
                    </a>
                  </div>

                  
                </div>
              )}
              {get_admin_profile?.insta && (
                <div className=" rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div className="d-flex align-items-center">
                    <a href={get_admin_profile?.insta} target="_blank">
                      <i className="fa-brands fa-instagram fs-2x"></i>
                    </a>
                  </div>
                </div>
              )}
              {get_admin_profile?.twitter && (
                <div className=" rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div className="d-flex align-items-center">
                    <a href={get_admin_profile?.twitter} target="_blank">
                      <i className="fa-brands fa-twitter fs-2x"></i>
                    </a>
                  </div>
                </div>
              )}
              {get_admin_profile?.youtube && (
                <div className=" rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div className="d-flex align-items-center">
                    <a href={get_admin_profile?.youtube} target="_blank">
                      <i className="fa-brands fa-youtube fs-2x"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
{/* 
      <FooterPageType />
      <Pageconect /> */}
      {/* <div className="mt-30">
        <Box3 />
      </div> */}
    </div>
  );
}

export default Setting;
